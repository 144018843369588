var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"footer__row"},[_c('div',{staticClass:"footer__col"},[_vm._m(0),_c('button',{staticClass:"footer__feedback btn btn--bordered btn--lg _orange",on:{"click":function($event){_vm.$modal.show('feedback');
            _vm.gtmEvent('help', 'help_click');}}},[_vm._v(" Связаться с нами ")])]),_c('div',{staticClass:"footer__col"},[_c('div',{staticClass:"footer__nav"},[_c('a',{staticClass:"footer__nav-link",attrs:{"href":`${_vm.publicPath}docs/rules.pdf`,"target":"_blank"},on:{"click":function($event){return _vm.gtmEvent('Пользовательское соглашение', 'footer')}}},[_vm._v(" Правила акции ")]),(_vm.user)?_c('router-link',{staticClass:"footer__nav-link",attrs:{"to":{ name: 'Profile' }},on:{"click":function($event){return _vm.gtmEvent('Личный кабинет', 'footer')}}},[_vm._v(" Личный кабинет ")]):_c('router-link',{staticClass:"footer__nav-link",attrs:{"to":{ name: 'Login' }},on:{"click":function($event){return _vm.gtmEvent('Личный кабинет', 'footer')}}},[_vm._v(" Личный кабинет ")])],1)]),_c('div',{staticClass:"footer__col"},[_c('button',{staticClass:"footer__btn btn btn--primary btn--sm",on:{"click":function($event){return _vm.toCheckUpload({
              site_section: 'main',
              action: 'check_download',
              eventContext: 'footer',
            })}}},[_vm._v(" Загрузить чек ")])])]),_c('div',{staticClass:"footer__bottom"},[_c('span',[_vm._v("Политика "),_c('a',{staticClass:"_underlined",attrs:{"href":`${_vm.publicPath}docs/policy.pdf`,"target":"_blank"},on:{"click":function($event){return _vm.gtmEvent('Политика конфиденциальности', 'footer')}}},[_vm._v("конфиденциальности")])]),_c('span',[_vm._v("Политика обработки "),_c('a',{staticClass:"_underlined",attrs:{"href":`${_vm.publicPath}docs/user_agreement.pdf`,"target":"_blank"},on:{"click":function($event){return _vm.gtmEvent('Политика обработки персональных данных', 'footer')}}},[_vm._v("персональных данных")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__title"},[_vm._v("Нужна "),_c('br'),_vm._v("помощь?")])
}]

export { render, staticRenderFns }