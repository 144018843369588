<template>
  <header class="header">
    <div class="container">
      <div class="header__row">
        <a href="#" class="header__5ka">
          <img src="../assets/images/magnit.svg" alt="" />
        </a>
        <router-link
          :to="{ name: 'Home' }"
          class="header__logo-wrapper"
          @click.native="showMenu = false"
        >
          <img
            src="@/assets/images/logo.svg"
            class="header__logo _left"
            alt=""
          />
        </router-link>
        <a
          :href="`${publicPath}docs/rules.pdf`"
          target="_blank"
          class="header__rules header__nav-item hidden-xs"
          @click="gtmEvent('Правила акции', 'menu')"
        >
          Правила акции
        </a>
        <router-link
          v-if="!user"
          class="header__login header__nav-item"
          :to="{ name: 'Signup' }"
          @click.native="gtmEvent('Личный кабинет', 'menu')"
        >
          <img src="../assets/images/icons/user-2.png" alt="" />
          <img src="../assets/images/icons/user.svg" alt="" />
          <span class="hidden-xs">Личный кабинет</span>
        </router-link>
        <template v-else>
          <router-link
            class="header__login header__nav-item"
            :to="{ name: 'Profile' }"
            @click.native="gtmEvent('Личный кабинет', 'menu')"
          >
            <img src="../assets/images/icons/user-2.png" alt="" />
            <img src="../assets/images/icons/user.svg" alt="" />
            <span class="hidden-xs">Личный кабинет</span>
          </router-link>
          <router-link
            to="/user-info"
            class="header__nav-item header__settings hidden-xs"
          >
            <img src="../assets/images/icons/settings-2.svg" alt="" />
            <img src="../assets/images/icons/settings.svg" alt="" />
          </router-link>
        </template>
        <button
          class="btn btn--primary btn--sm header__nav-item hidden-xs"
          @click="
            toCheckUpload({
              site_section: 'main',
              action: 'check_download',
              eventContext: 'header',
            })
          "
        >
          Загрузить чек
        </button>
        <div class="header__hamburger visible-xs" @click="showBurger()">
          <img
            v-if="showMenu"
            src="../assets/images/icons/close.svg"
            alt=""
            class="header__hamburger-pic"
          />
          <img
            v-else
            src="../assets/images/hamburger_icon.svg"
            alt=""
            class="header__hamburger-pic"
          />
        </div>
      </div>
    </div>
    <div class="header__menu" :class="{ _active: showMenu }">
      <div class="header__menu-wrapper">
        <a
          :href="`${publicPath}docs/rules.pdf`"
          target="_blank"
          @click="showBurger(), gtmEvent('Правила', 'menu')"
          class="header__menu-link"
        >
          Правила
        </a>
        <router-link
          v-for="(item, idx) in navigation"
          :key="idx"
          class="header__menu-link"
          active-class="_active"
          @click.native="showBurger(), gtmEvent(item.name, 'menu')"
          :to="{ name: item.path, hash: item.hash }"
        >
          {{ item.name }}
        </router-link>
      </div>
      <div class="header__menu-bottom">
        <router-link
          v-if="user"
          to="/user-info"
          @click.native="showBurger()"
          class="header__nav-item header__nav-info"
        >
          <img src="../assets/images/icons/settings.svg" alt="" />
        </router-link>
        <button @click="showBurger(), toCheckUpload()" class="btn btn--primary">
          <span>Загрузить чек</span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      showMenu: false,
      navigation: [
        // {
        //   name: "Победители",
        //   path: "/",
        // },
        {
          name: "Читать истории",
          path: "Home",
          hash: "#all-stories",
        },
        {
          name: "Где купить",
          path: "",
        },
        // {
        //   name: "Snickers®️ 30 лет в движении 2.0",
        //   path: "Home",
        // },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    homePage() {
      if (this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    clickPrize() {
      if (this.$store.getters.user) {
        let apmButton = document.querySelector(
          "#apm-scan-qr .apm-action-button"
        );
        if (apmButton) apmButton.click();
      } else {
        this.$modal.show("authorization");
      }
    },

    goTo() {
      this.$store.commit("SCROLL_TO_PRIZES", true);
      this.$router.push({ name: "Home" });
    },

    showModal(name) {
      this.$modal.show(name);
    },
    logout() {
      this.$store.dispatch("LogOut");
      document.location.reload();
    },
    showBurger() {
      this.showMenu = !this.showMenu;
    },
  },
  mounted() {
    // this.$store.dispatch("LogOut");
  },
};
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  font-family: $font-reg;
  background: rgba(252, 250, 245, 0.3);
  z-index: 999999;
  &._modified {
  }

  &.header--mob_active {
    width: 100%;
  }
  .container {
    position: relative;
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    z-index: 11;
  }
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &__5ka {
    margin-bottom: rem(-50px);
    img {
      height: rem(70px);
    }
  }
  &__logo {
    height: rem(70px);
    &-wrapper {
      flex-shrink: 0;
      flex-grow: 0;
      margin-left: rem(20px);
      margin-right: auto;
      margin-bottom: rem(-50px);
    }
  }
  &__hide-on_modified {
    transition: 0.4s;
    ._modified & {
      opacity: 0;
    }
  }
  &__show-on_modified {
    position: absolute;
    right: rem(0px);
    transform: translateY(-150%);
    transition: 0.4s;
    ._modified & {
      transform: translateY(0%);
    }
  }
  &__nav {
    position: relative;
    width: 100%;
    display: none;
    align-items: center;
    &-info {
      width: rem(55px);
      img {
        width: 100%;
      }
    }
  }
  &__hamburger {
    display: flex;
    align-items: center;
    font-size: rem(14px);
    margin-left: rem(20px);
    flex-shrink: 0;
    text-transform: uppercase;
    line-height: 1;
    z-index: 100;
    cursor: pointer;
    &-text {
      display: block;
      flex-shrink: 0;
      margin-left: rem(12px);
    }
    &-pic {
      width: rem(27px);
    }
  }

  &__menu {
    display: flex;
    position: absolute;
    width: 100vw;
    top: 0px;
    right: 0;
    height: 100vh;
    padding-top: vh(180px);
    padding-bottom: rem(20px);
    transform: translateY(-100%);
    transition: 0.5s;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: $blue_light;
    background-size: cover;
    opacity: 0.5;
    z-index: 1;
    &._active {
      transform: translateY(0%);
      opacity: 1;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: vh(107px);
    }
    &-link {
      font-family: $font-reg;
      font-size: rem(32px);
      margin-bottom: vh(15px);
    }
    &-bottom {
      display: flex;
    }
    .btn {
      width: auto;
      height: rem(55px);
      flex-shrink: 0;
      flex-grow: 0;
      margin-left: rem(12px);
      font-size: rem(28px);
    }
    &-img {
      position: absolute;
      object-fit: contain;
    }
  }
  &__login {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: $red;
    font-family: $font-reg;
    cursor: pointer;
    img {
      width: rem(40px);
      margin-right: rem(5px);
      &:last-of-type {
        display: none;
      }
    }
  }
  .btn {
    color: #fff;
  }
  @media (min-width: $sm) {
    &__logo {
      height: rem(38px);
    }
  }
  @media (min-width: $md) {
    font-size: rem(16px);
    width: 100%;
    &__show-on_modified {
      right: rem(150px);
    }
    &__row {
    }
    &__5ka {
      margin-bottom: rem(-22px);
      margin-right: rem(10px);
      img {
        height: rem(76px);
      }
    }
    &__login {
      position: relative;
      img {
        width: rem(24px);
        transition: 0.5s;
        &:first-of-type {
          opacity: 0;
        }
        &:last-of-type {
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &:hover {
        img {
          &:first-of-type {
            opacity: 1;
          }
          &:last-of-type {
            opacity: 0;
          }
        }
      }
    }
    &__settings {
      position: relative;
      margin-right: rem(-10px);
      img {
        width: rem(40px);
        margin: 0;
        transition: 0.5s;
        &:first-of-type {
          opacity: 0;
        }
        &:last-of-type {
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &:hover {
        img {
          &:first-of-type {
            opacity: 1;
          }
          &:last-of-type {
            opacity: 0;
          }
        }
      }
    }
    &__nav-item {
      margin-left: rem(25px);
      font-size: rem(20px);
      color: $grey;
      &.btn {
        padding: rem(0px) rem(30px);
        letter-spacing: -0.03em;
      }
    }
    &__logo {
      height: rem(90px);
      &-wrapper {
        margin-left: 0;
        margin-right: auto;
        margin-bottom: rem(-35px);
      }
    }
    &__hamburger {
      margin-left: rem(70px);
      &-pic {
        width: rem(35px);
      }
      &-text {
        font-size: rem(14px);
        letter-spacing: 0.08em;
      }
    }
    &__menu {
      padding-top: vh(130px);
      align-items: flex-start;
      justify-content: center;
      &-link {
        margin-bottom: vh(17px);
        font-size: vh(28px);
        line-height: vh(56px);
      }
      .btn {
        padding: vh(0px) vh(23px);
        height: vh(56px);
        font-size: vh(20px);
      }
      &-img {
        height: 37vh;
        opacity: 0;
        transform: translateX(100%);
        transition: 1s;
        transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
        &--1 {
          top: 10vh;
          right: vh(-160px);
          transition-delay: 0.5s;
        }
        &--2 {
          top: 37vh;
          right: vh(130px);
          transition-delay: 0.8s;
        }
        &--3 {
          top: 60vh;
          right: vh(-30px);
          transition-delay: 1.1s;
        }
        ._active & {
          transform: translateX(0%);
          opacity: 1;
        }
      }
    }
    .container {
      display: flex;
      padding-top: rem(8px);
      padding-bottom: rem(9px);
    }
  }
}
</style>
